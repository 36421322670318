mat-slider {
  width: 95% !important;
  margin: 0 11px !important;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--main-red-color) !important;
  --mdc-slider-focus-handle-color: var(--main-red-color) !important;
  --mdc-slider-hover-handle-color: var(--main-red-color) !important;
  --mdc-slider-active-track-color: var(--main-red-color) !important;
  --mdc-slider-inactive-track-color: var(--main-red-color) !important;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--main-red-color) !important;
  --mat-mdc-slider-ripple-color: var(--main-red-color) !important;
  --mat-mdc-slider-hover-ripple-color:unset !important;
  --mat-mdc-slider-focus-ripple-color:unset !important;
}
