@import "../../../assets/variables/responsive_sizes";

$width: 48px;
@mixin radio-button-styles($width) {

  .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
  .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
    background-color: var(--main-brand-red) !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--main-brand-red) !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--radio-border-color) !important;
  }

  .mat-mdc-radio-button .mdc-radio {
    width: $width / 2;
    height: $width / 2;
    padding: calc((var(--mdc-radio-state-layer-size, $width) - $width / 2) / 2) !important;
  }

  .mdc-radio__background {
    width: $width / 2 !important;
    height: $width / 2 !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
    top: calc(-1 * (var(--mdc-radio-state-layer-size, $width) - $width / 2) / 2) !important;
    left: calc(-1 * (var(--mdc-radio-state-layer-size, $width) - $width / 2) / 2) !important;
    width: var(--mdc-radio-state-layer-size, $width) !important;
    height: var(--mdc-radio-state-layer-size, $width) !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    top: calc((var(--mdc-radio-state-layer-size, $width) - var(--mdc-radio-state-layer-size, $width)) / 2) !important;
    right: calc((var(--mdc-radio-state-layer-size, $width) - var(--mdc-radio-state-layer-size, $width)) / 2) !important;
    left: calc((var(--mdc-radio-state-layer-size, $width) - var(--mdc-radio-state-layer-size, $width)) / 2) !important;
    width: var(--mdc-radio-state-layer-size, $width) !important;
    height: var(--mdc-radio-state-layer-size, $width) !important;
  }

  .mdc-radio__inner-circle {
    border-width: $width / 4 !important;
  }

  .mat-mdc-radio-touch-target {
    height: $width !important;
    width: $width !important;
  }
  .mat-mdc-radio-button .mat-radio-ripple {
    display: none !important;
  }

  .mdc-radio__background::before {
    display: none !important;

  }
}

@include radio-button-styles($width);


@media (max-width: map-get($desktopSizes, tablet)) {
  $width: 32px;
  @include radio-button-styles($width);
}

@media (max-width: map-get($desktopSizes, mobile)) {
  $width: 48px;
  @include radio-button-styles($width);
}
//.delivery_address_radio {
//  $width: 48px;
//  @include radio-button-styles($width);
//}
