@import "../../../assets/variables/responsive_sizes";

// Texts
.text_title_36 {
  font-size: 36px;
  line-height: 44px;
  color: var(--gray-black-color);
  font-weight: 800;
}

.text_title_big {
  font-size: 32px;
}

.text_title_middle {
  font-size: 24px;
  font-weight: 800;
  color: var(--gray-black-color);
}

.text_title {
  font-size: 20px;
}

.text_normal_sub {
  font-size: 16px;
}

.font_15 {
  font-size: 15px;
}

.font_17 {
  font-size: 17px;
}

.font_13 {
  font-size: 13px;
}

.text_normal {
  font-size: 14px;
}

.middle_small_text {
  font-size: 12px;
}

.small_text {
  font-size: 10px;
}

.text_gray_color {
  color: var(--text-gray-color);
}

.bold {
  font-weight: 500;
}

.bold_600 {
  font-weight: 600;
}

.bold_700 {
  font-weight: 700 !important;
}

.bold_800 {
  font-weight: 800;
}


.text_center {
  text-align: center;
}

// End of Texts

.active {
  color: var(--main-red-color);
  //border-bottom: 3px solid var(--main-red-color);
  transition: .3s;
}

.green_text {
  color: #6EB962 !important;
}

.mobile_active_route {
  color: var(--main-red-color) !important;
  transition: .3s;
}



@media (max-width: map-get($desktopSizes, tablet)) {
  .text_title_36{
    font-size: 24px !important;
  }
}
