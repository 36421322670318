.mat-mdc-chip-listbox {
  .mat-chip-list-wrapper {
    margin-top: 10px !important;
  }


  .mat-mdc-standard-chip {
    padding: 5px 16px;
    min-height: 26px !important;
    color: var(--gray-black-color);
    height: 37px;
    background-color: white !important;
    border-radius: 32px;
    margin: 0 8px 0 0 !important;
    border: 1px solid var(--second-Gray-4);

    .mat-mdc-chip-action {
      padding: 0 !important;
    }

    .mat-mdc-chip-remove {
      background: unset;
      opacity: 1;
      padding: 0 !important;
      margin-top: 2px;
      height: 24px !important;
      width: 24px !important;
      margin-left: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .mat-chip-trailing-icon {
    width: 24px !important;
    height: 24px !important;
  }
}

