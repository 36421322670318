.mat-calendar-content {
  padding: 0 !important;
}

mat-datepicker-content {
  border-radius: 24px !important;
  padding: 0 28px 28px 28px !important;

  .mat-calendar-body-cell-content.mat-focus-indicator {
    background-color: var(--gray-background-color);
    border-radius: 12px;
    border: 1px solid var(--rgb-48-1);
    width: 32px;
    height: 32px;

  }

  .mat-calendar-body-selected {
    background-color: var(--black-color) !important;
    color: var(--white-color) !important;
  }

  .mat-calendar-body-label {
    opacity: 0 !important;
  }

  .mat-calendar-table-header {
    tr {
      display: none !important;
    }
  }

  .mat-calendar-controls {
    display: grid !important;

    .mat-calendar-previous-button {
      position: absolute;
      top: 20px;
      left: 75px;

    }

    .mat-calendar-next-button {
      position: absolute;
      top: 20px;
      right: 75px;
    }
  }


  .mat-mdc-button, .mat-mdc-outlined-button {
    --mat-mdc-button-persistent-ripple-color: unset !important;
  }

}

.mat-datepicker-content .mat-calendar {
  height: 322px !important;
}
mat-multi-year-view {
  position: relative;
  left: 13px;

  .mat-calendar-body-cell-content.mat-focus-indicator {
    width: 44px !important;
    height: 33px !important;
  }
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: unset !important;
  --mat-mdc-button-ripple-color: unset !important;
}

mat-year-view {
  position: relative;
  left: 13px;

  .mat-calendar-body-cell-content.mat-focus-indicator {
    width: 44px !important;
    height: 33px !important;
  }
}

.mat-calendar-arrow {
  display: none !important;
}
