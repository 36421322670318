.main_buttons {
  background: unset;
  border-radius: 20px;
  outline: unset;
  border: unset;
  cursor: pointer;
  color: var(--white-color);
  width: initial;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;

  &:hover, &:active, &:focus {
    outline: 0;
    box-shadow: none;
    cursor: pointer;
  }

  //&:disabled {
  //  opacity: 0.5;
  //}
}

.pointer {
  cursor: pointer
}


.light_btn {
  background-color: var(--gray-background-color);
  padding: 5px 16px;
  color: var(--text-gray-color);

  &:hover {
    background-color: var(--gray-color);
    color: white;
  }
}

.green_btn {
  color: var(--approve-green-text);
  background-color: var(--approve-green-color);
  border-radius: 20px;
}

.red_btn {
  color: var(--decline-red-text);
  background-color: var(--decline-red-color);
  border-radius: 20px;
}


.black_btn {
  background-color: var(--gray-color);
  color: var(--white-color);
  border: 2px solid var(--gray-color);

  &.with_padding {
    height: 52px;
    font-size: 15px;
  }
}

.gray_btn {
  background: var(--disable-bg-color) !important;
  border: 2px solid var(--disable-color) !important;
  color: var(--text-gray-color) !important;
}

.empty_page_button {
  padding: 22px 20px;
  width: calc(100% - 42px);
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid var(--second-Gray-4);
  color: var(--gray-black-color);
}

.main_red_btn {
  background: var(--main-brand-red) !important;
  border: unset !important;
  padding: 23px;
  font-size: 17px;
  color: var(--white-color) !important;
  box-sizing: border-box;
}

.follow_btn {
  border: 1px solid var(--rgb-255-1);
  color: var(--text-gray-color);
  overflow: visible;
  padding: 12px 22px;

}

.active_btn {
  background-color: var(--gray-color);
  color: white;
  outline: unset;
  border: unset;
  padding: 12px 47px;
  border-radius: 12px;
}

.big_btn {
  padding: 22px 0 ;
  font-weight: 500;
  font-size: 16px;
}

.save_button_container {
  padding: 15px 24px;

  &--btn {
    padding: 18px 0;
    border-radius: 20px;
    background-color: black;
    color: var(--white-color);
    width: 100%;
    border: none;
  }
}
