// main colors.scss
* {
  margin: 0;
  padding: 0;
  font-family: Mulish;
  scroll-behavior: smooth;
  line-height: initial;
  &:focus-visible{
    outline: none;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

.mat-body, .mat-body-1, .mat-typography {
  font-size: 12px !important;
  font-family: Mulish;
  font-weight: 500;
}

// Custom Styles
@import "app";
@import "./variables/colors";
@import "./scss/global/scrollbar";
@import "scss/global/buttons";
@import "scss/global/components";
@import "scss/global/flex";
@import "scss/global/position";
@import "scss/global/inputs";
@import "scss/global/texts";
@import "scss/global/margin_padding_width_height";
@import "scss/material-angular/select.scss";
@import "scss/material-angular/auto_complete";
@import "scss/global/overflow";
@import "scss/material-angular/range_slider.scss";
@import "scss/material-angular/dialog";
@import "scss/material-angular/date_picker";
@import "scss/material-angular/chips";
@import "scss/material-angular/tabs";
@import "scss/material-angular/checkbox";
@import "scss/material-angular/radio";
@import 'node_modules/swiper/swiper-bundle';
@import "node_modules/swiper/components/a11y/a11y.scss";
@import "node_modules/swiper/components/navigation/navigation";
@import "node_modules/swiper/components/pagination/pagination";
@import "scss/global/otp";
@import "scss/swiper/swiper";
