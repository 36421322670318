@import "../../../assets/variables/responsive_sizes";

.ngx-otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 12px !important;
  border: none !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  background-color: var(--gray-background-color);
}

.ngx-otp-input-container {
  width: 336px;
  justify-content: space-between;
}

ngx-otp-input {

  .my-super-success-class {
    background-color: var(--success-green-color) !important;
  }

  .my-super-error-class {
    background-color: var(--error-label);
  }
}


@media (max-width: map-get($desktopSizes, mobile)) {
  .ngx-otp-input {
    width: 40px !important;
    height: 50px !important;
  }
  ngx-otp-input{
    width: 100% !important;
  }
  .ngx-otp-input-container {
    width: 100% !important;
  }
}
