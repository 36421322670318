.flow_hide {
  overflow: hidden;
}

.flow_auto {
  overflow: auto;
}

.flow_x_auto {
  overflow-x: auto;
}

.flow_y_auto {
  overflow-y: auto;
}

.flow_y_hidden {
  overflow-y: hidden;
}

.flow_x_hide {
  overflow-x: hidden;
}
