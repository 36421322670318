.flex_between_align_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_end_align_center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex_start_align_center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex_center_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.flex_space_evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex_align_center {
  display: flex;
  align-items: center;
}

.flex_around_align_center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex {
  display: flex;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.flex_column_center_between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.flex_column_center_align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_column_between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
}

.flex_colum_end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex_align_end {
  align-items: end;
}
