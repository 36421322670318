
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$click_market_client-primary: mat.define-palette(mat.$indigo-palette);
$click_market_client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$click_market_client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$click_market_client-theme: mat.define-light-theme((color: (primary: $click_market_client-primary,
        accent: $click_market_client-accent,
        warn: $click_market_client-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($click_market_client-theme);

/* You can add global styles to this file, and also import other style files */
@import "assets/main";

/*toaster styles for global usage*/
@import 'ngx-toastr/toastr';


@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/font/Mulish-Bold.ttf');
  font-weight: 900;
}
