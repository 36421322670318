@import "../../../assets/variables/responsive_sizes";

mat-select {
  &.gender {
    .mat-mdc-select-trigger {
      height: 68px;
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mdc-list-item__primary-text {
  width: 100% !important;
}

.border_bottom {
  border-bottom: 1px solid var(--white-color);
  padding-top: 11px;
  padding-bottom: 15px;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  visibility: hidden !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}


.mdc-line-ripple {
  border: none;
}

.mdc-line-ripple::before {
  border: none !important;
}

.mdc-line-ripple::before {
  border: none !important;
}

.mat-mdc-select-arrow {
  svg {
    display: none !important;
  }
}

.mat-mdc-select {
  width: 256px !important;
  background: none;
}

.mat-mdc-select-trigger {
  padding: 12px 20px;
  background-color: var(--background-Input-Gray);
  border-radius: 16px;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  border-radius: 16px !important;
}

.with-checkbox .mat-mdc-option .mat-pseudo-checkbox-minimal {
  background-image: url('../../../assets/images/icons/filtration/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  color: var(--main-red-color);
  background-color: unset;
  font-weight: 700;
}


.mdc-list-item--selected .mdc-list-item__primary-text {
  color: unset !important;
}

.mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: unset !important;
}

.cdk-overlay-container:has(.with-shadow) .cdk-overlay-backdrop-showing {
  opacity: 1;
  visibility: visible !important;
  background-color: var(--rgb-transperent-32) !important;
}


.cdk-overlay-container:has(.big_panel) .cdk-overlay-pane {
  width: 368px !important;
}

.mat-mdc-select-arrow-wrapper {
  background-image: url('../../../assets/images/icons/filtration/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  color: var(--main-red-color);
  background-color: unset;
  font-weight: 700;
  width: 24px;
  height: 24px;
}


@media (max-width: map-get($desktopSizes, mobile)) {
  mat-select {
    &.sort {
      .mat-mdc-select-trigger {
        background-color: unset !important;
      }

      .mat-mdc-select-arrow {
        background-image: unset !important;
      }
    }

    &.gender {
      .mat-mdc-select-trigger {
        font-size: 14px !important;
        height: 56px !important;
      }

      .mat-mdc-select-value {
        margin-left: 6px !important;
      }
    }

  }

}


@media (min-width: map-get($desktopSizes, mobile)) and (max-width: map-get($desktopSizes, tablet)-1) {
  mat-select {
    &.gender {
      .mat-mdc-select-trigger {
        height: 56px !important;
      }
    }
  }
}

@media (min-width: map-get($desktopSizes, tablet)) and (max-width: map-get($desktopSizes, laptop)-1) {
  mat-select {
    &.gender {
      .mat-mdc-select-trigger {
        height: 60px !important;
      }
    }
  }
}

