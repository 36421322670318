@import "../../../assets/variables/responsive_sizes";

.mat-mdc-tab-body-wrapper {
  min-height: 570px !important;
}

.mat-mdc-tab {

  .mdc-tab-indicator__content--underline {
    border-width: 2px !important;
    border-color: var(--main-brand-red) !important;
    top: 48px;
    position: absolute;
  }

  & .mdc-tab__ripple, & .mat-ripple {
    display: none !important;
  }

  .mdc-tab__text-label {
    font-size: 36px !important;
    font-weight: 800 !important;
    font-family: Mulish !important;
    letter-spacing: normal !important;
    color: #D9D9D9 !important;
    margin-bottom: 20px;
  }
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--gray-black-color) !important;
}

.catalog_shop_products_tab {
  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }

  .mat-mdc-tab {
    padding: 0 !important;
    margin-right: 16px;
  }

  .mat-mdc-tab-list {
    border-bottom: 2px solid var(--white-color);
  }

  & .mat-mdc-tab-labels {
    width: fit-content !important;
  }
}


@media (max-width: map-get($desktopSizes, mobile)) {
  .mat-mdc-tab {

    .mdc-tab-indicator__content--underline {
      display: none !important;
    }

    .mdc-tab__text-label {
      font-size: 32px !important;
      font-weight: 800 !important;
      font-family: Mulish !important;
      letter-spacing: normal !important;
      color: #D9D9D9 !important;
      margin-bottom: 0 !important;
    }
  }


  .catalog_shop_products_tab {
    .mat-mdc-tab {
      margin-right: 12px !important;
    }
  }
}
