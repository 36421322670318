@import "../../../assets/variables/responsive_sizes";

// TOGGLE CHECKBOX

.mat-mdc-slide-toggle {
  transform: scale(1.5);

  .mdc-switch__ripple, .mdc-switch__shadow, .mdc-switch__icon {
    display: none !important;
  }

  .mdc-switch__handle {
    left: 4px;
  }

  .mdc-switch__handle:before {
    display: none !important;
  }

  .mdc-switch__handle:after {
    width: 18px !important;
    height: 18px !important;
    left: -2px !important;
    top: 1px;
    background-color: var(--white-color) !important;
  }

  .mdc-switch__track {
    height: 22px !important;
    border-radius: 24px !important;
    background: var(--rgb-48-1) !important;
  }
}


.mdc-switch--checked {
  .mdc-switch__handle:after {
    width: 18px !important;
    height: 18px !important;
    top: 1px;
    background-color: var(--white-color) !important;
    left: -4px !important;
  }

  .mdc-switch__track:after {
    border-radius: 24px !important;
    background-color: var(--main-brand-red) !important;
  }

  .mat-slide-toggle-thumb-container {
    left: 8px !important;
  }
}


.hidden_input {
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
}


@media (max-width: map-get($desktopSizes, mobile)-1) {
  .mat-mdc-slide-toggle {
    transform: scale(1.3);
  }
}
