*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent !important
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-tumb-color);
  border-radius: 10px;
  border: 2px solid var(--scrollbar-tumb-border-color);
}
