.mdc-dialog__surface {
  border-radius: 16px !important;
}


.ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
  background-image: url('../../../assets/images/icons/orders/zoom-in.svg') !important;
}

.ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon{
  background-image: url('../../../assets/images/icons/orders/zoom-out.svg') !important;
}


.ymaps-2-1-79-zoom__plus, .ymaps-2-1-79-zoom__minus {
  width: 52px !important;
  height: 52px !important;
  padding: 0 !important;
  border-radius: 16px !important;
  justify-content: center  !important;
  align-items: center !important;
  display: flex !important;
}

.ymaps-2-1-79-float-button-icon{
  position: unset !important;
}

.ymaps-2-1-79-zoom__minus {
  top: 72px !important;
}


.custom_geolocation_icon {
  img {
    width: 100%;
  }
  padding: 14px;
  display: flex;
  justify-content: center;
  background-color: var(--white-color);
  border-color: transparent;
  box-shadow: 0 1px 2px 1px var(--rgb-geolocation), 0 2px 5px -3px var(--rgb-geolocation);
  border-width: 1px;
  border-style: solid;
  vertical-align: middle;
  font-family: Arial,Helvetica,sans-serif;
  transition: background-color .15s ease-out,border-color .15s ease-out,opacity .15s ease-out;
  &:active{
    background-color: var(--active-dialog-color);
  }
}


//bottom-sheet

.mat-bottom-sheet-container{
  padding: unset !important;
  border-radius: 24px 24px 0 0 !important;
  overflow: hidden !important;
}
