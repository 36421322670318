:root {
  --main-red-color: #E85749;
  --small-screen_red-color: #F85251;
  --main-light-red-color: #e857491a;
  --dot-red-color: #FD4C3B;
  --decline-red-color: #FDEEED;
  --decline-red-text: #B22113;
  --gray-color: #303030;
  --main-brand-red: #FF4230;
  --text-gray-color: #979797;
  --disable-btn-color: #EAEAEA;
  --black-color: #000000;
  --gray-black-color: #444;
  --green-color: #54B644;
  --approve-green-color: #EFFDED;
  --approve-green-text: #60C623;
  --gray-background-color: #F7F8F9;
  --white-color: #FFFFFF;
  --second-Gray-4: #E6E6E6;
  --background-Input-Gray: #F4F4F4;
  --error-label: #FBEFEE;
  --disable-bg-color: #EBEBEB;
  --disable-color: #D9D9D9;
  --delete-color: #FDF1F0;
  --scrollbar-tumb-color: #C1C1C1;
  --scrollbar-tumb-border-color: #F8F8F8;
  --success-green-color: #acf5ac;
  --active-dialog-color: #f3f1ed;
  --rgb-48-5: rgba(48, 48, 48, 0.05);
  --rgb-48-10: rgba(48, 48, 48, 0.10);
  --rgb-48-16: rgba(48, 48, 48, 0.16);
  --rgb-48-2: rgba(48, 48, 48, 0.2);
  --rgb-48-1: rgba(48, 48, 48, 0.1);
  --rgb-255-1: rgba(255, 255, 255, 0.1);
  --rgb-255-10: rgba(255, 255, 255, 0.10);
  --rgb-214-1: rgba(214, 214, 214, 1);
  --rgb-transperent-1: rgba(0, 0, 0, 0.1);
  --rgb-transperent-3: rgba(0, 0, 0, 0.03);
  --rgb-transperent-4: rgba(0, 0, 0, 0.04);
  --rgb-transperent-32: rgba(0, 0, 0, 0.32);
  --rgb-blue-transperent: rgba(247, 248, 249, 1);
  --rgb-gray-color-transperent: rgba(30, 30, 30, 0.2);
  --rgb-gray-color-transperent-5: rgba(30, 30, 30, 0.05);
  --rgb-geolocation: rgba(0, 0, 0, .15);
  --main-Base-Gray-for-text: #838383;

  //box-shadow
  --box-shadow-black: 0px 20px 60px rgba(0, 0, 0, 0.1);
  --box-shadow-black-slider: 0px 2px 20px 0px #0000001A;
  --box-shadow-0-04: rgba(0, 0, 0, 0.04);
  --radio-border-color: #cfcfcf;

  //mobile
  --light-blue: #EBEDF4
}
