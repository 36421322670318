.open_component{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  background: var(--white-color);
  padding: 85px 24px;
  height: calc(100% - 80px);
}

.hidden {
  display: none;
}
