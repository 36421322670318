.position_relative {
  position: relative;
}

.hidden {
  visibility: hidden;
}
.overflow_hidden {
  overflow-y: hidden !important;
}

.all_filters_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.37);
  transition: 100ms all;
}
