@import "../../../assets/variables/responsive_sizes";

.app_input {
  position: relative;

  &.invalid {
    input {
      border: 1px solid var(--decline-red-text);
    }
  }

  & input {
    width: 100%;
    box-sizing: border-box;
    border: var(--text-gray-color);
    background-color: var(--gray-background-color);
    padding: 12px 16px 12px 16px;
    border-radius: 20px;
    outline: unset;
    height: unset;


  }

  .icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.checkbox, .radio {
  display: block;
  height: 18px;
  width: 18px;
  position: relative;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }


  & .checkmark {
    outline: 2px solid var(--rgb-gray-color-transperent);
    border: 2px solid var(--gray-background-color);
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: var(--gray-background-color);
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: none;
    }
  }

  & input:checked ~ .checkmark_radio {
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    transition: 0.4s;
  }

  & input:checked ~ .checkmark {
    border: 2px solid var(--gray-background-color);
    outline: 2px solid var(--rgb-gray-color-transperent);
    background-color: var(--main-brand-red);
  }

  .checked {
    img {
      display: block;
    }
  }
}


@media (max-width: map-get($desktopSizes, mobile)) {
  .checkbox, .radio {

    & .checkmark {
      height: 18px !important;
      width: 18px !important;
    }

  }
}
