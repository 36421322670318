@import "../assets/variables/responsive_sizes";

.main {
  height: 100vh;
  overflow-y: auto;
  scrollbar-gutter: stable;

  .body_container {
    background-color: var(--white-color);
    padding-top: 164px;
    min-height: 100vh;
  }

  .container {
    max-width: 1140px;
    margin: 0 auto;

    &-content {
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: map-get($desktopSizes, mobile)) {
  body {
    overflow: unset !important;
  }
  .main {
    overflow-y: unset;
  }
  .body_container {
    padding-top: 80px !important;
  }
}


