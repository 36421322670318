@import "../../../assets/variables/responsive_sizes";

// Margins
.ml_auto {
  margin-left: auto
}

.ml_40 {
  margin-left: 40px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_8 {
  margin-left: 8px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_4 {
  margin-left: 4px;
}

.ml_2 {
  margin-left: 2px;
}

.ml_12 {
  margin-left: 12px;
}

.ml_16 {
  margin-left: 16px;
}

.mr_16 {
  margin-right: 16px;
}

.mr_20 {
  margin-right: 20px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_5 {
  margin-right: 5px;
}

.mr_8 {
  margin-right: 8px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_32 {
  margin-top: 32px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_12 {
  margin-top: 12px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_28 {
  margin-top: 28px;
}

.mt_20 {
  margin-top: 20px;
}


.mt_60 {
  margin-top: 60px;
}

.mt-16 {
  margin-top: 16px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_18 {
  margin-top: 18px;
}

.mt_8 {
  margin-top: 8px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_4 {
  margin-top: 4px;
}

.mb_32 {
  margin-bottom: 32px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_24 {
  margin-bottom: 24px;
}

.margin_auto{
  margin: 0 auto;
}

// End of Margins

// Paddings


.p_20 {
  padding: 20px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_24 {
  padding-top: 24px;
}

.pt_35 {
  padding-top: 35px;
}

.pb_24 {
  padding-bottom: 24px;
}

.pb_40 {
  padding-bottom: 40px;
}

.pb_35 {
  padding-bottom: 35px;
}

.pl_60 {
  padding-left: 60px;
}

.pl_pr_16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pl_16 {
  padding-left: 16px;
}

.pr_8 {
  padding-right: 8px;
}

.p_rl_152 {
  padding: 0 152px;
}

.p_rl_10 {
  padding: 0 1px;
}

.p_rl_20 {
  padding: 0 20px;
}

.p_rl_30 {
  padding: 0 30px;
}

.p_10 {
  padding: 10px;
}

.padding_unset {
  padding: unset;
}

// End of Paddings

.w_100 {
  width: 100%
}

.w_40 {
  width: 40%
}

.w_45 {
  width: 45%;
}

.w_48 {
  width: 48%;
}

.w_50 {
  width: 50%;
}

.w_55 {
  width: 55%;
}


.h_100 {
  height: 100%;
}

.w_80 {
  width: 80%;
}

.w_60_max {
  max-width: 60%
}

.w_max_cont {
  width: max-content;
}

//borders

.br_50 {
  border-radius: 50%;
}

.br_32 {
  border-radius: 32px;
}

.br_16 {
  border-radius: 16px;
}

.br_24 {
  border-radius: 24px;
}

.br_20 {
  border-radius: 20px;
}

.br_12 {
  border-radius: 12px;
}

.br_8 {
  border-radius: 8px;
}


@media (max-width: map-get($desktopSizes, mobile)) {
  .mb_32 {
    margin-bottom: 16px !important;
  }
  .mt_60 {
    margin-top: 24px;
  }
}
