@import "../../../assets/variables/responsive_sizes";


@media (max-width: map-get($desktopSizes, mobile)) {
  .cdk-overlay-pane {
    margin-top: 10px;

    .mdc-menu-surface.mat-mdc-autocomplete-panel {
      height: calc(100vh - 250px);
      max-height: unset;
      box-shadow: none !important;
      padding-right: 24px !important;

      .mat_auto_complete_option {
        .mdc-list-item__primary-text {
          .border_bottom {
            border-top: 1px solid var(--rgb-48-5);
            padding: 18px 0 !important;

            img:not(:first-child) {
              display: none;
            }
          }


        }
      }

      .searched_values {
        .label_text {
          font-size: 14px !important;
          font-weight: 500;
          border-top: 1px solid var(--rgb-48-5);
          padding: 18px 0 !important;

        }
      }
    }

  }
}
