.swiper-pagination-bullet-active {
  background-color: #FFFFFF !important;
  width: 20px !important;
  border-radius: 4px !important;
  transition: width 0.4s ease !important;
}


.swiper-pagination-bullets {
  bottom: 10px;
  width: auto;
  background: #999492c7 !important;
  border-radius: 7px;
  padding-left: 4px;
  padding-right: 4px;
}

.swiper-pagination {
  z-index: 2;
  bottom: 8px;
}

.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: #c9c6c4;
  opacity: 1;
  margin: 0 2px;
}
